import Treemap from 'treemap-chart'

function sizeForList({ name }) {
  return {
    S: 1,
    M: 4,
    L: 16,
  }[name]
}

function colorForList({ name }) {
  // https://coolors.co/fde8e9-e3bac6-bc9ec1-596475-1f2232
  return {
    S: '#FDE8E9',
    M: '#E3BAC6',
    L: '#BC9EC1',
  }[name]
}

function transformCustomFieldItems(customFieldItems) {
  return {
    percentComplete: (
      customFieldItems.find(
        ({ idCustomField }) => idCustomField === '5d8afc23d4cf4b252fa459f0'
      ) || {}
    ).numberValue,
    roadmapUrl: (
      customFieldItems.find(
        ({ idCustomField }) => idCustomField === '5d8aeab340aa560bda4b1a58'
      ) || {}
    ).stringValue,
  }
}

export function transform(
  {
    data: {
      getBoard: { lists },
    },
  },
  { withDonePercentage }
) {
  const projects = lists
    .reduce((accum, { name, cards }) => {
      const size = sizeForList({ name })
      const color = colorForList({ name })

      return accum.concat(
        cards.map(({ name, desc, url, customFieldItems }) => {
          const { percentComplete = 0, roadmapUrl } = transformCustomFieldItems(
            customFieldItems
          )
          const completedSize = (percentComplete / 100.0) * size
          return {
            _totalSize: size,
            name,
            desc,
            url,
            roadmapUrl,
            color,
            size: size - completedSize,
            children: withDonePercentage
              ? [
                  {
                    name: 'done',
                    color,
                    size: completedSize,
                  },
                ]
              : [],
          }
        })
      )
    }, [])
    .sort((a, b) => 1000 * (b._totalSize - a._totalSize) + a.size - b.size)

  return {
    name: 'current',
    color: '#122C34',
    children: projects,
  }
}

function getProjectNode(node) {
  return node.parent.id === 0 ? node : node.parent
}

function onHover(node) {
  if (!node) {
    return
  }

  const projectNode = getProjectNode(node.__dataNode)
  const {
    x0,
    y0,
    x1,
    y1,
    data: { url: cardUrl, roadmapUrl },
  } = projectNode

  const controlElement = document.getElementById('controls')
  controlElement.style.visibility = 'visible'
  controlElement.style.left = `${x0}px`
  controlElement.style.width = `${x1 - x0}px`
  controlElement.style.top = `${y0}px`
  controlElement.style.height = `${y1 - y0}px`

  const roadmapLinkElement = document.querySelector('.controls .roadmap-link')
  if (roadmapUrl) {
    roadmapLinkElement.style.visibility = 'visible'
    roadmapLinkElement.href = roadmapUrl
  } else {
    roadmapLinkElement.style.visibility = 'hidden'
  }

  const cardLinkElement = document.querySelector('.controls .card-link')
  cardLinkElement.href = cardUrl
}

export function createChart({ results, element, withDonePercentage }) {
  Treemap()
    .width(window.innerWidth)
    .height(window.innerHeight)
    .padding(10)
    .data(transform(results, { withDonePercentage }))
    .size('size')
    .color('color')(element)
    .excludeRoot(true)
    .tooltipTitle((data, node) => {
      const {
        data: { name },
      } = getProjectNode(node)
      return name
    })
    .tooltipContent((data, node) => {
      const {
        data: { desc },
      } = getProjectNode(node)
      return desc
    })
    .onHover(onHover)
    .onClick(() => {})

  if (withDonePercentage) {
    setTimeout(() => {
      // On the rendered chart, half of the rect's are for the whole of the
      // project, and the other half are for the "done" part of the project. We
      // want to apply some theming to the "done" parts.
      // TODO Add a hook in `treemap-chart` for this.
      // https://github.com/vasturiano/treemap-chart/issues/1
      const rectElements = document.querySelectorAll('#chart .node')

      const doneOverlays = Array.from(rectElements).slice(
        rectElements.length / 2
      )
      for (const el of doneOverlays) {
        el.classList.add('done-overlay')
      }
    }, 0)
  }
}
