import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  gql,
} from 'apollo-boost'

const API_URL = 'https://lifework-api.herokuapp.com'
// const API_URL = 'http://localhost:4000'

export function createClient({ credentials }) {
  const authLink = new ApolloLink((operation, forward) => {
    const { password: token } = credentials.get()
    if (token) {
      const authHeader = `Bearer ${token}`
      operation.setContext({ headers: { authorization: authHeader } })
    }

    return forward(operation)
  })
  const httpLink = new HttpLink({ uri: API_URL })

  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  })
}

export async function fetch({ client, boardId }) {
  return client.query({
    query: gql`
      query($boardId: String!) {
        getBoard(boardId: $boardId) {
          url
          lists {
            name
            cards {
              name
              url
              desc
              labels {
                name
                color
              }
              customFieldItems {
                idCustomField
                stringValue
                numberValue
              }
            }
          }
        }
      }
    `,
    variables: { boardId },
  })
}
