function transform({
  data: {
    getBoard: { lists },
  },
}) {
  let totalCount = 0
  const result = { S: 0, M: 0, L: 0, XL: 0 }
  lists.forEach(({ name, cards }) => {
    const thisCount = cards.length
    result[name] = thisCount
    totalCount += thisCount
  })
  result.total = totalCount
  return result
}

export function updateStats({ results, element }) {
  // TODO Add a hook in `treemap-chart` for this.
  // https://github.com/vasturiano/treemap-chart/issues/2
  const counts = transform(results)
  for (const el of document.querySelectorAll('.stats dd')) {
    const list = el.className
    const thisCount = counts[list]
    if (thisCount) {
      el.style.visibility = 'visible'
      el.innerHTML = counts[list]
    } else {
      el.style.visibility = 'hidden'
    }
  }
  for (const el of document.querySelectorAll('.stats dt')) {
    const list = el.className
    const thisCount = counts[list]
    if (thisCount) {
      el.style.display = 'inline'
    } else {
      el.style.display = 'none'
    }
  }
}
