import LocalCredentialStorage from 'local-credential-storage'
import { createClient, fetch } from './data'
import { createChart } from './chart'
import { updateStats } from './stats'

const ANIMATION_DURATION_MILLIS = 800
const BOARD_IDS = {
  current: '5d8a1034682665157cfe085c',
  done: '5d8a0f57b94cd0260ab2c8f8',
}

let whichBoard = 'current'
let results
let settingsVisible = false

const credentials = new LocalCredentialStorage('com.m6ize.worklife')
let client = createClient({ credentials })

function setLoading(isLoading) {
  if (isLoading) {
    document.body.classList.add('loading')
  } else {
    document.body.classList.remove('loading')
  }
}

function removeChildren(element) {
  while (element.firstChild) {
    element.removeChild(element.firstChild)
  }
}

async function refresh() {
  setLoading(true)

  try {
    results = await fetch({ client, boardId: BOARD_IDS[whichBoard] })
  } catch (e) {
    if (!settingsVisible) {
      toggleSettings()
    }
  }

  render()

  setTimeout(() => setLoading(false), ANIMATION_DURATION_MILLIS)
}

function render() {
  const chartContainer = document.getElementById('chart')
  const statsElement = document.getElementById('stats')

  const {
    data: {
      getBoard: { url: boardUrl },
    },
  } = results
  document.getElementById('board-link').href = boardUrl

  removeChildren(chartContainer)
  createChart({
    results,
    withDonePercentage: whichBoard === 'current',
    element: chartContainer,
  })
  updateStats({ results, element: statsElement })
}

async function flip() {
  whichBoard = whichBoard === 'current' ? 'done' : 'current'
  refresh()
}

function toggleSettings() {
  settingsVisible = !settingsVisible

  if (settingsVisible) {
    const { password } = credentials.get()
    document.getElementById('token-input').value = password
    document.getElementById('settings').style.visibility = 'visible'
  } else {
    document.getElementById('settings').style.visibility = 'hidden'
  }
}

async function main() {
  document.getElementById('flip-link').onclick = e => {
    e.preventDefault()
    flip(results)
  }

  document.getElementById('settings-link').onclick = e => {
    e.preventDefault()
    toggleSettings()
  }

  document.getElementById('settings-form').onsubmit = e => {
    e.preventDefault()
    toggleSettings()
    const newToken = document.getElementById('token-input').value
    credentials.set('x', newToken)
    client = createClient({ credentials })
    refresh()
  }

  window.onresize = render
  refresh()
}

if (document.readyState !== 'loading') {
  setTimeout(main(), 0)
} else {
  document.addEventListener('DOMContentLoaded', main)
}
